<template>
  <!-- Note: several SVG and path attributes omitted for brevity -->
  <svg
    :class="selected ? 'selected' : ismobile ? 'mobile' : 'unselected'"
    role="img"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.01669 2.36664L3.52502 5.86664C2.77502 6.44997 2.16669 7.69164 2.16669 8.63331V14.8083C2.16669 16.7416 3.74169 18.325 5.67502 18.325H15.325C17.2584 18.325 18.8334 16.7416 18.8334 14.8166V8.74997C18.8334 7.74164 18.1584 6.44997 17.3334 5.87497L12.1834 2.26664C11.0167 1.44997 9.14169 1.49164 8.01669 2.36664Z"
      stroke="#8BABB6"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.25 9.58331L10.75 13.0833L9.41667 11.0833L6.75 13.75"
      stroke="#8BABB6"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5833 9.58331H14.25V11.25"
      stroke="#8BABB6"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "HomeTrendUpSvg",

  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    ismobile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
path {
  stroke: #8babb6;
}

.selected path {
  stroke: white;
}

.mobile path {
  stroke: #092a35;
}
</style>
