<template>
  <el-dialog
    title="Change Password"
    :modal-append-to-body="false"
    :visible.sync="dialogPassword"
  >
    <div class="dialog_content">
      <el-form :model="ruleForm" status-icon :rules="rules"  ref="ruleForm">
        <el-form-item prop="password" v-show="$store.state.userInfo.has_usable_password">
          <p class="dialog_tip">Old Password</p>
          <el-input type="password" autocomplete="new-password" show-password v-model="ruleForm.password" ></el-input>
        </el-form-item>
        <el-form-item prop="confirm_password">
          <p class="dialog_tip">New Password</p>
          <el-input type="password" autocomplete="new-password" show-password v-model="ruleForm.new_password" ></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: right">
        <el-button class="primary-button" style="width: 100px" @click="submitForm('ruleForm')" >Save</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import {  apiEmail, apiResetpassword } from "../../API/api";
export default {
  props: ["email"],
  data() {
    var validatePass = (rule, value, callback) => {
      let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{7,32}$/;
      if (value === "") {
        callback(new Error("Please input the password"));
      }  else {
        if (!reg.test(value)) {
          callback(
            new Error(
              "Your password must be between 7 and 32 characters and contain at least one letter and one number"
            )
          );
        } else {
          callback();
        }
      }
    };
    return {
      dialogPassword: false,
      ruleForm: { },
      rules: {
        password: [{ required: true, message: "this information is required", trigger: ["change", "blur"] }],
        new_password: [
          { validator: validatePass, trigger: ["change", "blur"] },
        ],
      },
      title: "",
    };
  },

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          delete this.ruleForm.confirm_password;
          apiResetpassword(this.ruleForm)
            .then((res) => {
              this.clear();
              this.$message.success("Modification succeeded");
              this.dialogPassword = false;
            })
            .catch((error) => {
              this.$message.error(error.response.data.detail);
            });
        } else {
          return false;
        }
      });
    },
    clear() {
        this.ruleForm.password = this.ruleForm.new_password =  "";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/dialog.scss";
@import "../../css/button.scss";
.top_box {
  background: #fff;
  .dialog_tip {
    text-align: left;
    margin: 0;
    line-height: 30px;
  }
   
  /deep/ .el-dialog__body {
    padding: 20px;
  }
}
/deep/ .el-dialog {
  width: 400px;
    .el-form-item {
      margin-bottom: 10px;
    }
  @media (max-width: 992px) {
    width: 90%;
  }
}
</style>
