<template>
  <div class="contact">
    <el-dialog title="Profile" :visible.sync="show" @open="open" :modal-append-to-body="false">
      <el-form :model="data" ref="data">
        <div class="agent-content row">
          <div class="agent-photo">
            <div class="upload">
              <el-upload class="upload-demo" action="demo"
                :show-file-list="false"
                :http-request="uploadImages"
                v-loading="loadingImg"
                element-loading-spinner="el-icon-loading"
              >
                <img :src="data.avatar ? data.avatar : img" class="agent-img" />
              </el-upload>
            </div>
          </div>
          <div class="agent-table">
            <div class="row name-row">
              <el-form-item>
                <p class="agent-tip">First Name</p>
                <el-input v-model="data.first_name"></el-input>
              </el-form-item>
              <el-form-item>
                <p class="agent-tip">Last Name</p>
                <el-input v-model="data.last_name"></el-input>
              </el-form-item>
            </div>
            <div class="row move-row">
              <div class="upload">
                <el-upload
                  class="upload-demo"
                  action="demo"
                  :show-file-list="false"
                  :http-request="uploadImages"
                  v-loading="loadingImg"
                  element-loading-spinner="el-icon-loading"
                >
                  <img
                    :src="data.avatar ? data.avatar : img"
                    class="agent-img"
                  />
                </el-upload>
              </div>
              <div class="move-item">
                <el-form-item>
                  <p class="agent-tip">First Name</p>
                  <el-input v-model="data.first_name"></el-input>
                </el-form-item>
                <el-form-item>
                  <p class="agent-tip">Last Name</p>
                  <el-input v-model="data.last_name"></el-input>
                </el-form-item>
              </div>
            </div>
            <el-form-item>
                <p class="agent-tip">Title</p>
                <el-input v-model="data.pm.title"></el-input>
              </el-form-item>
            <el-form-item>
              <p class="agent-tip">Phone</p>
              <el-input
                v-model="data.phone"
                maxlength="22"
                @input="(e) => (data.phone = changePhone(e))"
              ></el-input>
            </el-form-item>

            <div>
              <p class="agent-tip" style="margin-bottom: 1px">Agent Bio</p>
              <tinymce v-model="data.bio" ref="presentation" :height="100" />
            </div>
          </div>
        </div>
        <div class="btn">
          <el-button class="light-blue-button" @click="show = false">Cancel</el-button>
          <el-button
            class="primary-button"
            @click="publish"
            :loading="loading"
            >Save</el-button
          >
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import Tinymce from "../tinymce";
import { Minixs } from "../../js/mixins";
import {apiUploadImage, apiUpdateProfile } from "../../API/api";
export default {
  mixins:[Minixs],
  components: { Tinymce } ,
  props: ["infor"],
  data() {
    return {
      show: false,
      loadingImg: false,
      loading: false,
      img: require("../../assets/img/person.png"),
      data:{
        avatar: "",
        bio: null,
        first_name: "",
        last_name: "",
        phone: "",
        pm: {title:""}
      },
    };
  },
  methods: {
    open() {
      this.data.first_name = this.infor.first_name;
        this.data.last_name = this.infor.last_name;
        this.data.avatar = this.infor.avatar;
        this.data.phone = this.infor.phone;
        this.data.bio = this.infor.bio;
        this.data.pm.title = this.infor.pm.title;
    },
    uploadImages(file) {
      let files = new FormData();
      files.append("file", file.file);
      this.loadingImg = true;
      apiUploadImage(files).then((res) => {
          this.data.avatar = res.small_url;
        }) .finally(() => {
          this.loadingImg = false;
        });
    },
    publish(){
      this.$refs.data.validate(valid => {
        if (valid) {
          apiUpdateProfile(this.data).then(res => {
              this.$store.commit("setUserInfo", res);
              this.$message.success("Success");
            }).catch(err => {
              this.$message.error("Please resubmit");
            }).finally(()=>{
              this.show = false;
            });
        } else {
          return false;
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/dialog.scss";
@import "../../css/button.scss";
.contact {
  .agent-content {
    flex-direction: column;
    margin-top: 20px;
    align-items: flex-start;
    .agent-photo {
      margin-right: 30px;
    }
    .upload {
      text-align: center;
      .agent-img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-bottom: 10px;
        object-fit: cover;
      }
    }
    .agent-table {
      /deep/ input::-webkit-outer-spin-button,
      /deep/ input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      /deep/ input[type="number"] {
        -moz-appearance: textfield;
      }
      .name-row {
        justify-content: space-between;
        /deep/ .el-form-item {
          width: calc(calc(100% - 10px) / 2);
        }
      }
      /deep/ .el-form-item {
        margin-bottom: 15px;
        .el-input,
        .el-select {
          width: 100%;
        }
      }
      .agent-tip {
        margin: 0;
        text-align: left;
        line-height: 18px;
        color: #38425b;
        font-size: "Roboto-Regular", sans-serif;
        font-size: 14px;
      }
    }
    .move-row {
      display: none;
      .move-item {
        width: calc(100% - 120px);
      }
    }
  }
  .btn {
    text-align: right;
    margin-top: 20px;
    .el-button {
      width: 100px;
    }
  }
  /deep/ .el-dialog {
    width: 600px;
  }
  @media (max-width: 992px) {
    /deep/ .el-dialog {
      width: 90%;
    }
    .agent-content {
      .move-row {
        display: flex;
        justify-content: space-between;
      }
      .agent-photo {
        display: none;
      }
      .agent-table {
        width: 100%;
        .name-row {
          display: none;
        }
      }
    }
  }
}
</style>