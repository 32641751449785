<template>
  <!-- Note: several SVG and path attributes omitted for brevity -->
  <svg
    :class="selected ? 'selected' : ismobile ? 'mobile' : 'unselected'"
    role="img"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      d="M10.6001 10.65C10.5417 10.6417 10.4667 10.6417 10.4001 10.65C8.93339 10.6 7.76672 9.39998 7.76672 7.92498C7.76672 6.41665 8.98339 5.19165 10.5001 5.19165C12.0084 5.19165 13.2334 6.41665 13.2334 7.92498C13.2251 9.39998 12.0667 10.6 10.6001 10.65Z"
      stroke="#8BABB6"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.1166 16.15C14.6333 17.5083 12.6666 18.3333 10.5 18.3333C8.3333 18.3333 6.36663 17.5083 4.8833 16.15C4.96663 15.3667 5.46663 14.6 6.3583 14C8.64163 12.4833 12.375 12.4833 14.6416 14C15.5333 14.6 16.0333 15.3667 16.1166 16.15Z"
      stroke="#8BABB6"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.5 18.3334C15.1023 18.3334 18.8333 14.6024 18.8333 10C18.8333 5.39765 15.1023 1.66669 10.5 1.66669C5.89759 1.66669 2.16663 5.39765 2.16663 10C2.16663 14.6024 5.89759 18.3334 10.5 18.3334Z"
      stroke="#8BABB6"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "AdminSvg",

  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    ismobile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
path {
  stroke: #8babb6;
}

.selected path {
  stroke: white;
}

.mobile path {
  stroke: #092a35;
}
</style>
