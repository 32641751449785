<template>
  <!-- Note: several SVG and path attributes omitted for brevity -->
  <svg
    :class="selected ? 'selected' : ismobile ? 'mobile' : 'unselected'"
    role="img"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
  >
    <path
      d="M13.78 5.96667L9.4333 10.3133C8.91997 10.8267 8.07997 10.8267 7.56664 10.3133L3.21997 5.96667"
      stroke="#8BABB6"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowDownSvg",

  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    ismobile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
path {
  stroke: #8babb6;
}

.selected path {
  stroke: white;
}

.mobile path {
  stroke: #092a35;
}
</style>
