<template>
  <!-- Note: several SVG and path attributes omitted for brevity -->
  <svg role="img" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M0 15.5582C0 16.1718 0.497461 16.6693 1.11111 16.6693H18.8889C19.5025 16.6693 20 16.1718 20 15.5582C20 14.9445 19.5025 14.447 18.8889 14.447H1.11111C0.497461 14.447 0 14.9445 0 15.5582ZM0 10.0026C0 10.6163 0.497461 11.1137 1.11111 11.1137H18.8889C19.5025 11.1137 20 10.6163 20 10.0026C20 9.38895 19.5025 8.89149 18.8889 8.89149H1.11111C0.497461 8.89149 0 9.38895 0 10.0026ZM1.11111 3.33594C0.497461 3.33594 0 3.8334 0 4.44705C0 5.0607 0.497461 5.55816 1.11111 5.55816H18.8889C19.5025 5.55816 20 5.0607 20 4.44705C20 3.8334 19.5025 3.33594 18.8889 3.33594H1.11111Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "HamburgerSvg",
};
</script>
