<template>
  <el-container class="box_contanin">
    <PageHeader />
    <div class="box_contanin_main">
      <el-container>
        <el-main>
          <router-view v-show="isRouterAlive" />
        </el-main>
      </el-container>
    </div>
  </el-container>
</template>
<script>
import $ from "jquery";
import PageHeader from "../components/common/PageHeader";
export default {
  components: {  PageHeader },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
      btnFlag: false,
      isCollapse: false,
    };
  },
  methods: {
    toggleCollapse(item) {
      this.isCollapse = item;
      this.$refs.productImage.getSrcList(item);
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function() {
        this.isRouterAlive = true;
      });
    },
    // / 点击图片回到顶部方法，加计时器是为了过渡顺滑
    backTop() {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },

    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop() {
      const that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 60) {
        $(".siade_right .sr_bar").css("top", "10px");
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
        $(".siade_right .sr_bar").css("top", "100px");
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>
<style lang="scss" scoped>

.box_contanin {
  height: 100%;
  .box_contanin_main {
    margin-top: 80px;
    width: 100%;
  }
  .back_top {
    position: fixed;
    bottom: 200px;
    z-index: 1;
    right: 5%;
    width: 50px;
    height: 50px;
  }
  .el-header {
    position: fixed;
    z-index: 999;
    padding: 0;
  }
  .el-footer {
    padding: 0;
  }
  .el-main {
    min-height: 850px;
    padding: 20px 50px;
    background-color: #fff;
  }
  .el-main:has(.contact-detail-page){
    background:none;
  }
  .nav_text {
    position: fixed;
    z-index: 3;
  }
  .move.el-header {
    display: none;
    width: 100%;
    width: 100%;
    z-index: 20009;
  }
  @media (max-width: 992px) {
    .el-main {
      padding: 16px 24px;
    }
    .pc {
      display: none;
    }
    .move.el-header {
      display: block;
    }
  }
}
</style>
