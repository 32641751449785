<template>
  <el-header class="page-header box_contanin_header">
    <div class="header-wrapper">
      <el-dropdown class="dropdown" trigger="click">
        <el-button class="icon-btn hamburger">
          <HamburgerSvg />
        </el-button>
        <el-dropdown-menu slot="dropdown" style="width:calc(100% - 12px)">
          <el-menu default-active="2" class="el-menu-vertical-demo">
            <el-menu-item index="1">
              <div style="display:flex; align-items:center; height:100%" @click="clickMenu('/contacts')">
                <ContactSVG :ismobile="true" />
                <div style="margin-left:10px; color:#092A35;" class="typography_p3">
                  Contacts
                </div>
              </div>
            </el-menu-item>
            <el-submenu index="2">
              <template slot="title">
                <div style="display:flex; align-items:center; height:100%" @click="openRentals()">
                  <HomeTrendSVG :ismobile="!isRentalsOpen ? true : false" />
                  <div style="margin-left:10px; margin-right:8px;" class="typography_p3"
                    :style="{ color: isRentalsOpen ? '#8BABB6' : '#092A35' }">
                    Rentals
                  </div>
                </div>
              </template>
              <el-menu-item index="2-1">
                <div class="dropdown_text" @click="clickMenu('/properties')">
                  Property Listings
                </div>
              </el-menu-item>
              <el-menu-item index="2-2">
                <div class="dropdown_text" @click="clickMenu('/leases')">
                  Leases
                </div>
              </el-menu-item>
              <el-menu-item index="2-3">
                <div class="dropdown_text" @click="clickMenu('/lease-report')">
                  Payment Report
                </div>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="3" v-if="isType ? true : false">
              <template slot="title">
                <div style="display:flex; align-items:center; height:100%" @click="openAdmin()">
                  <AdminSvg :ismobile="!isAdminOpen ? true : false" />
                  <div style="margin-left:10px; margin-right:8px;" class="typography_p3"
                    :style="{ color: isAdminOpen ? '#8BABB6' : '#092A35' }">
                    Admin
                  </div>
                </div>
              </template>
              <el-menu-item index="3-1" v-if="isType ? true : false">
                <div class="dropdown_text" @click="clickMenu('/schedule')">
                  Schedule
                </div>
              </el-menu-item>
              <el-menu-item index="3-2" v-if="isType ? true : false">
                <div class="dropdown_text" @click="
                  redirect(
                    'https://wordpress.congdonandcoleman.com/wp-admin/'
                  )
                ">
                  Blog
                </div>
              </el-menu-item>
              <el-menu-item index="3-3" v-if="isType ? true : false">
                <div class="dropdown_text" @click="clickMenu('/congdonand-coleman-agents')">
                  Agents
                </div>
              </el-menu-item>
              <el-menu-item index="3-4" v-if="isType ? true : false">
                <div class="dropdown_text" @click="clickMenu('/tools-resources')">
                  Tools & Resources
                </div>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="4">
              <template slot="title">
                <div style="display:flex; align-items:center; height:100%" @click="openResources()">
                  <EditSVG :ismobile="!isResourcesOpen ? true : false" />
                  <div style="margin-left:10px; margin-right:8px;" class="typography_p3"
                    :style="{ color: isResourcesOpen ? '#8BABB6' : '#092A35' }">
                    Resources
                  </div>
                </div>
              </template>
              <el-menu-item index="4-2">
                <div class="dropdown_text" @click="redirect('https://live.activepipe.com/dashboard')">
                  ActivePipe
                </div>
              </el-menu-item>
              <el-menu-item index="4-3">
                <div class="dropdown_text" @click="redirect('https://app.docusign.com/home')">
                  DocuSign
                </div>
              </el-menu-item>
              <el-menu-item index="4-4">
                <div class="dropdown_text" @click="redirect('https://nantucketma.mapgeo.io/')">
                  GIS Maps
                </div>
              </el-menu-item>
              <el-menu-item index="4-5">
                <div class="dropdown_text" @click="
                  redirect(
                    'https://records.nantucket-ma.gov/WebLink/Browse.aspx?id=145009&dbid=0&repo=TownofNantucket'
                  )
                ">
                  Health Department
                </div>
              </el-menu-item>
              <el-menu-item index="4-5">
                <div class="dropdown_text" @click="redirect('http://nantucket.mylinkmls.com/')">
                  LINK
                </div>
              </el-menu-item>
              <el-menu-item index="4-5">
                <div class="dropdown_text" @click="redirect('https://www.masslandrecords.com/')">
                  Mass Land Records
                </div>
              </el-menu-item>
              <el-menu-item index="4-5">
                <div class="dropdown_text" @click="redirect('http://my.moxiworks.com/')">
                  MoxiWorks
                </div>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </el-dropdown-menu>
      </el-dropdown>

      <div class="header-wrapper_logo">
        <img src="../../assets/icon/logo.svg" class="logo" @click="clickLogo" />
        <img src="../../assets/icon/mob-logo.svg" class="mob-logo" @click="clickLogo" />
      </div>
      <div class="header-wrapper_nav">
        <div class="menu-group">
          <div style="display:flex; align-items:center" class="icon-menu"
            :class="$route.path === '/contacts' && 'selected'" @click="clickMenu('/contacts')">
            <ContactSVG :selected="$route.path === '/contacts'" />
            <div style="margin-left:10px" class="typography_p3">
              Contacts
            </div>
          </div>
          <div class="nav-menu">
            <el-dropdown trigger="click" placement="bottom-start">
              <div style="display:flex; align-items:center" class="icon-menu" :class="isRentalsSelected && 'selected'">
                <HomeTrendSVG :selected="isRentalsSelected" />
                <div style="margin-left:10px; margin-right:8px" class="typography_p3">
                  Rentals
                </div>
                <ArrowDownSVG />
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <div class="dropdown_text" @click="clickMenu('/properties')">
                    Property Listings
                  </div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div class="dropdown_text" @click="clickMenu('/leases')">
                    Leases
                  </div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div class="dropdown_text" @click="clickMenu('/lease-report')">
                    Payment Report
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="nav-menu">
            <el-dropdown trigger="click" placement="bottom-start">
              <div style="display:flex; align-items:center" class="icon-menu">
                <EditSVG />
                <div style="margin-left:10px; margin-right:8px" class="typography_p3">
                  Resources
                </div>
                <ArrowDownSVG />
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <div class="dropdown_text" @click="redirect('https://live.activepipe.com/dashboard')">
                    ActivePipe
                  </div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div class="dropdown_text" @click="redirect('https://app.docusign.com/home')">
                    DocuSign
                  </div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div class="dropdown_text" @click="redirect('https://nantucketma.mapgeo.io/')">
                    GIS Maps
                  </div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div class="dropdown_text" @click="
                    redirect(
                      'https://records.nantucket-ma.gov/WebLink/Browse.aspx?id=145009&dbid=0&repo=TownofNantucket'
                    )
                  ">
                    Health Department
                  </div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div class="dropdown_text" @click="redirect('http://nantucket.mylinkmls.com/')">
                    LINK
                  </div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div class="dropdown_text" @click="redirect('https://www.masslandrecords.com/')">
                    Mass Land Records
                  </div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div class="dropdown_text" @click="redirect('http://my.moxiworks.com/')">
                    MoxiWorks
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="nav-menu">
            <el-dropdown trigger="click" placement="bottom-start" v-if="isType ? true : false">
              <div style="display:flex; align-items:center" class="icon-menu" :class="isAdminSelected && 'selected'">
                <AdminSvg :selected="isAdminSelected" />
                <div style="margin-left:10px; margin-right:8px" class="typography_p3">
                  Admin
                </div>
                <ArrowDownSVG />
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-if="isType ? true : false">
                  <div class="dropdown_text" @click="clickMenu('/schedule')">
                    Schedule
                  </div>
                </el-dropdown-item>
                <el-dropdown-item v-if="isType ? true : false">
                  <div class="dropdown_text" @click="
                    redirect(
                      'https://wordpress.congdonandcoleman.com/wp-admin/'
                    )
                  ">
                    Blog
                  </div>
                </el-dropdown-item>
                <el-dropdown-item v-if="isType ? true : false">
                  <div class="dropdown_text" @click="clickMenu('/congdonand-coleman-agents')">
                    Agents
                  </div>
                </el-dropdown-item>
                <el-dropdown-item v-if="isType ? true : false">
                  <div class="dropdown_text" @click="clickMenu('/tools-resources')">
                    Tools & Resources
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <!-- <el-button class="icon-btn"><NotificationSvg /></el-button> -->

        <div class="nav_right row">
          <el-dropdown @command="handleCommand" class="dropdown" trigger="click">
            <span class="avatar_text">
              <div class="nav_name">
                {{ $store.state.userInfo.first_name }}
                {{ $store.state.userInfo.last_name }}
              </div>
              <span class="nav_photo">
                <img :src="
                  !$store.state.userInfo.avatar
                    ? headImg
                    : $store.state.userInfo.avatar
                " />
              </span>
              <ArrowDownSVG :selected="true" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a">
                <div class="dropdown_text">
                  Profile
                </div>
              </el-dropdown-item>
              <el-dropdown-item command="b">
                <div class="dropdown_text">
                  Change Password
                </div>
              </el-dropdown-item>
              <el-dropdown-item command="c">
                <div class="dropdown_text">
                  Log Out
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <!-- <div class="header-wrapper_nav-mobile">
        <el-button
          v-if="showMove === true"
          icon="el-icon-close fa-2x"
          @click="showMove = false"
        />
        <el-button
          v-else
          icon="el-icon-s-operation fa-2x"
          @click="showMove = true"
        />
        <mt-popup
          v-model="showMove"
          :modal="false"
          class="mt-popup"
          position="bottom"
        >
          <div style="padding: 15px;">
          </div>
          <Small-Navigation
            @changePassword="handleCommand('b')"
            @logout="handleCommand('c')"
          ></Small-Navigation>
        </mt-popup>
      </div> -->
      <ProFileInfor ref="profile" :infor="$store.state.userInfo"></ProFileInfor>
      <Change-Password ref="psw" :email="email"></Change-Password>
    </div>
  </el-header>
</template>
<script>
import { apiGetProfile } from "../../API/api";
import { Base64 } from "js-base64";
import ContactSVG from "../../assets/svgs/contact.vue";
import HomeTrendSVG from "../../assets/svgs/home-trend-up.vue";
import ArrowDownSVG from "../../assets/svgs/arrow-down.vue";
import EditSVG from "../../assets/svgs/edit.vue";
import AdminSvg from "../../assets/svgs/admin.vue";
import NotificationSvg from "../../assets/svgs/notification.vue";
import HamburgerSvg from "../../assets/svgs/hamburger.vue";
import AvatarImg from "../../assets/img/person.png";
import ChangePassword from "./ChangePassword";
import ProFileInfor from "../profile";
import storage from "../../js/storage";

export default {
  data() {
    return {
      email: "",
      isRentalsSelected:
        this.$router.history.current.path === "/properties" ||
        this.$router.history.current.path === "/leases" ||
        this.$router.history.current.path === "/lease-report",
      isAdminSelected:
        this.$router.history.current.path === "/schedule" ||
        this.$router.history.current.path === "/congdonand-coleman-agents" ||
        this.$router.history.current.path === "/tools-resources",
      headImg: AvatarImg,
      showMove: false,
      title: "",
      titleData: "",
      isRentalsOpen: false,
      isAdminOpen: false,
      isResourcesOpen: false,
      isType: false,
    };
  },
  beforeCreate() {
    apiGetProfile().then((res) => {
      this.email = res.email;
      sessionStorage.setItem("uersId", Base64.encode(`${res.user_id ?? ''}`));
      this.$store.commit("setUserInfo", res);
    });
  },
  components: {
    ContactSVG,
    HomeTrendSVG,
    ArrowDownSVG,
    EditSVG,
    AdminSvg,
    NotificationSvg,
    ChangePassword,
    ProFileInfor,
    HamburgerSvg,
  },
  methods: {
    clickLogo() {
      this.$router.push({ path: "/" });
    },
    redirect(link) {
      window.open(link, "_blank");
    },
    clickMenu(path) {
      this.$router.push({ path: path });
    },
    handleCommand(command) {
      if (command === "a") {
        this.$refs.profile.show = true;
      } else if (command === "b") {
        this.close();
        this.$refs.psw.dialogPassword = true;
      } else {
        storage.removeItem('token');
        storage.removeItem('isType');
        storage.removeItem('name');
        storage.removeItem('count');
        sessionStorage.clear();
        this.$router.push("/login");
      }
    },
    replaceTitle() {
      this.titleData = this.titleData.split("/", 2);
      if (this.titleData[1] === "agents" || this.titleData[1] === "agent") {
        this.title = "Agents";
      } else if (this.titleData[1] === "dashboard") {
        this.title = "Dashboard";
      } else if (
        this.titleData[1] === "contacts" ||
        this.titleData[1] === "contact"
      ) {
        this.title = "Contacts";
      } else if (
        this.titleData[1] === "homeowners" ||
        this.titleData[1] === "homeowner"
      ) {
        this.title = " Homeowners";
      } else if (
        this.titleData[1] === "leases" ||
        this.titleData[1] === "lease"
      ) {
        this.title = "Leases";
      } else if (
        this.titleData[1] === "properties" ||
        this.titleData[1] === "property"
      ) {
        this.title = "Properties";
      } else if (this.titleData[1] === "templates") {
        this.title = "Drive";
      } else if (this.titleData[1] === "schedule") {
        this.title = "Schedule";
      } else if (this.titleData[1] === "profile") {
        this.title = "Profile";
      } else if (
        this.titleData[1] === "blogs" ||
        this.titleData[1] === "blog"
      ) {
        this.title = "Blog";
      } else {
        this.title = "Resources";
      }
    },
    close() {
      this.showMove = false;
    },
    openRentals() {
      this.isRentalsOpen = !this.isRentalsOpen;
    },
    openAdmin() {
      this.isAdminOpen = !this.isAdminOpen;
    },
    openResources() {
      this.isResourcesOpen = !this.isResourcesOpen;
    },
  },
  created() {
    this.titleData = this.$route.path;
    this.replaceTitle();
    this.isType = !!storage.getItem("isType");
  },
  watch: {
    "$route.path"(newV, oldV) {
      this.titleData = newV;
      this.replaceTitle();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/common.scss";

.box_contanin_header {
  width: 100%;
  background: #092a35;
  height: 80px !important;

  .header-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 90;

    .hamburger {
      padding: 16px;
      display: none;
    }

    .header-wrapper_logo {
      padding: 22px 50px;
      margin-right: 156px;

      .logo {
        cursor: pointer;
        visibility: visible;
        display: block;

        @media (max-width: 1152px) {
          display: none;
          visibility: hidden;
        }
      }

      .mob-logo {
        cursor: pointer;
        visibility: hidden;
        display: none;

        @media (max-width: 1152px) {
          display: block;
          visibility: visible;
        }
      }
    }
  }

  .header-wrapper_nav {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-right: 50px;

    .menu-group {
      display: flex;
    }

    .nav-menu {
      margin-left: 50px;
      display: flex;
    }
  }

  .icon-btn {
    background: transparent;
    border: none;
    padding: 8px;
  }

  .icon-menu {
    cursor: pointer;
    color: #8babb6;

    &.selected {
      color: white;
    }
  }

  .nav_right {
    &.row{
      flex: auto 0 ;
      margin:0px;
    }
    .nav_photo img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    .dropdown {
      color: #ffffff;
      cursor: pointer;

      .avatar_text {
        display: flex;
        align-items: center;

        .nav_photo {
          margin-left: 15px;
          margin-right: 15px;
        }
      }
    }
  }
}

@media (max-width: 1422px) {
  .box_contanin_header {
    .header-wrapper {
      .header-wrapper_logo {
        padding: 22px;
        margin-left: 8px;
        margin-right: 24px;
      }

      .header-wrapper_nav {
        .nav-menu {
          margin-left: 24px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .box_contanin_header {
    .header-wrapper {
      .header-wrapper_logo {
        padding: 0px;
      }

      .hamburger {
        display: block;
      }

      .header-wrapper_nav {
        justify-content: flex-end;
        padding-right: 16px;

        .menu-group {
          display: none;
          visibility: hidden;
        }

        .nav_name {
          display: none;
        }
      }
    }
  }
}
</style>
