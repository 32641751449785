<template>
  <!-- Note: several SVG and path attributes omitted for brevity -->
  <svg
    :class="selected ? 'selected' : ismobile ? 'mobile' : 'unselected'"
    role="img"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      d="M11.55 3.00002L4.70835 10.2417C4.45002 10.5167 4.20002 11.0584 4.15002 11.4334L3.84169 14.1334C3.73335 15.1084 4.43335 15.775 5.40002 15.6084L8.08335 15.15C8.45835 15.0834 8.98335 14.8084 9.24168 14.525L16.0834 7.28335C17.2667 6.03335 17.8 4.60835 15.9583 2.86668C14.125 1.14168 12.7334 1.75002 11.55 3.00002Z"
      stroke="#8BABB6"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.4083 4.20831C10.7667 6.50831 12.6333 8.26665 14.95 8.49998"
      stroke="#8BABB6"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3 18.3333H18"
      stroke="#8BABB6"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "EditSvg",

  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    ismobile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
path {
  stroke: #8babb6;
}

.selected path {
  stroke: white;
}

.mobile path {
  stroke: #092a35;
}
</style>
