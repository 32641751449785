<template>
  <!-- Note: several SVG and path attributes omitted for brevity -->
  <svg
    :class="selected ? 'selected' : ismobile ? 'mobile' : 'unselected'"
    role="img"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      d="M8.13332 9.05841C8.04999 9.05008 7.94999 9.05008 7.85832 9.05841C5.87499 8.99175 4.29999 7.36675 4.29999 5.36675C4.29999 3.32508 5.94999 1.66675 7.99999 1.66675C10.0417 1.66675 11.7 3.32508 11.7 5.36675C11.6917 7.36675 10.1167 8.99175 8.13332 9.05841Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.175 3.33325C15.7916 3.33325 17.0917 4.64159 17.0917 6.24992C17.0917 7.82492 15.8417 9.10825 14.2833 9.16659C14.2167 9.15825 14.1417 9.15825 14.0667 9.16659"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.96666 12.1333C1.95 13.4833 1.95 15.6833 3.96666 17.0249C6.25833 18.5583 10.0167 18.5583 12.3083 17.0249C14.325 15.6749 14.325 13.4749 12.3083 12.1333C10.025 10.6083 6.26666 10.6083 3.96666 12.1333Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.7833 16.6667C16.3833 16.5417 16.95 16.3001 17.4167 15.9417C18.7167 14.9667 18.7167 13.3584 17.4167 12.3834C16.9583 12.0334 16.4 11.8001 15.8083 11.6667"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ContactSvg",

  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    ismobile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
path {
  stroke: #8babb6;
}

.selected path {
  stroke: white;
}

.mobile path {
  stroke: #092a35;
}
</style>
